import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  section: {
    width: '60%', 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px',
    margin: '0 auto',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  heading: {
    marginBottom: '20px',
    color: '#243C53',
    fontSize: '50px',
    '@media (max-width: 1200px)': {
      fontSize: '25px',
      marginBottom: '20px',
    },
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    '@media (max-width: 1200px)': {
      marginRight: '0',
      marginBottom: '20px',
    },
  },
  image: {
    maxWidth: '512px',
    height: '512px',
    '@media (max-width: 1200px)': {
      maxWidth: '50%',
      height: '50%',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    marginTop: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
});

export default useStyles;
