import { axiosRequest } from './axios'

export class ClientServices {
  private static instance: ClientServices;
  
  private constructor() {}

  public static getInstance(): ClientServices {
    if (!ClientServices.instance) {
      ClientServices.instance = new ClientServices();
    }
    return ClientServices.instance;
  }

  async getEmailInfo(email: any) {
    try {
      const { data } = await axiosRequest.get<any>(`/landingRegister/email/${email}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDataByIdMercado(idMercado: any) {
    try {
      const { data } = await axiosRequest.get<any>(`/landingRegister/idMercado/${idMercado}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async putNuevaCompra(body: any) {
    try {
      const { data } = await axiosRequest.put<any>(`/landingRegister`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchCambiarType(id: string, type: string) {
    try {
      const { data } = await axiosRequest.patch<any>(`/landingRegister/${id}/type/${type}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getConsultaEmail(email: string) {
    try {
      const { data } = await axiosRequest.get<any>(`/landingInfo/email/${email}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async postConsultaEmail(body: any) {
    try {
      const { data } = await axiosRequest.post<any>(`/landingInfo`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  
}