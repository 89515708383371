// store/alertStore.ts
import { create } from 'zustand';

interface AlertState {
  isVisible: boolean;
  imageSrc: string;
  text: string;
  onClose: boolean;
  showAlert: (imageSrc: string, text: string, onClose: boolean) => void;
  hideAlert: () => void;
}

const useAlertStore = create<AlertState>((set: (arg0: { isVisible: boolean; imageSrc?: any; text?: any; onClose?: any; }) => any) => ({
  isVisible: false,
  imageSrc: '',
  text: '',
  onClose: false,
  showAlert: (imageSrc: any, text: any, onClose: any) =>
    set({ isVisible: true, imageSrc, text, onClose }),
  hideAlert: () => set({ isVisible: false }),
}));

export default useAlertStore;
