import useStyles from './styles'; // Importa los estilos
import { Button } from '@mui/material';
import klausLogo from '../../assets/images/klausLogo.png';
import sigeaLogo from '../../assets/images/sigeaLogo.png';

const Header = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.images}>
        <img src={klausLogo} alt='Klaus Logo'/>
        <img src={sigeaLogo} alt='Sigea Logo' />
      </div>
      <div className={classes.buttonsDiv}>
        <Button sx={{color: '#243C53'}} className={classes.buttons} variant='text' href='#plataforma-asg'>Plataforma ASG</Button>
        <Button sx={{color: '#243C53'}} className={classes.buttons} variant='text' href='#planes'>Planes</Button>
        <Button sx={{color: '#243C53'}} className={classes.buttons} variant='text' href='#contacto'>Contacto</Button>
      </div>
    </header>
  );
};

export default Header;
