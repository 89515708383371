import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useStyles from "./styles";
import plan_logo_main from '../../../assets/images/plan_logo_main.png';
import plan_logo1 from '../../../assets/images/plan_logo1.png';
import plan_logo2 from '../../../assets/images/plan_logo2.png';
import plan_logo3 from '../../../assets/images/plan_logo3.png';
import plan_logo4 from '../../../assets/images/plan_logo4.png';
import plan_logo5 from '../../../assets/images/plan_logo5.png';
import plan_formulario from '../../../assets/images/plan_formulario.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createPayment } from '../../mercadoPago/services';
import useAlertStore from '../../../state/alertState';
import { ClientServices } from '../../../services/client.service.ts';

const listData = [
  { imgSrc: plan_logo1, text: 'Membresía anual' },
  { imgSrc: plan_logo2, text: '1 Razón social por plan' },
  { imgSrc: plan_logo3, text: 'Hasta 50 Megas de almacenamiento en Microsoft Azure' },
  { imgSrc: plan_logo4, text: 'Máximo 5 usuarios' },
  { imgSrc: plan_logo5, text: 'Soporte técnico de lunes a viernes de 9:00 AM a 5:00 PM, hora de Ciudad de México' },
];

// Datos de la tabla
const productData = [
  { id: 1, descripcion: 'Distintivo ESR', precio: 39000},
  { id: 2, descripcion: 'Certificación GRI', precio: 39000},
  { id: 3, descripcion: 'Distintivo y Certificación GRI', precio: 39000},
  { id: 4, descripcion: 'Distintivo ESR y/o Certificación GRI con SAGA', precio: 39000},
  { id: 5, descripcion: 'Certificación ASG', precio: 50000},
  { id: 6, descripcion: 'Distintivo ESR con certificación ASG', precio: 50000},
  { id: 7, descripcion: 'GRI con certificación ASG', precio: 50000},
  { id: 8, descripcion: 'Distintivo ESR y/o GRI con certificación ASG con SAGA', precio: 50000},
];

// Función para formatear el precio
const formatPrice = (amount: number) => {
  return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const PlanesContent = () => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState(1); // Estado para almacenar la fila seleccionada
  const { showAlert } = useAlertStore();
  
  const handleRowSelect = (id: number) => {
    setSelectedRow(id); // Actualiza el estado con el ID de la fila seleccionada
  };

  // Configuración de Formik
  const formik = useFormik({
    initialValues: {
      name: 'Test Name',
      rfc: 'TEST111111AXE',
      email: '',
      phone: '1234567890',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obligatorio'),
      rfc: Yup.string().required('Campo obligatorio').matches(/^[A-Z0-9]{12,13}$/, 'RFC no válido'),
      email: Yup.string().email('Email no válido').required('Campo obligatorio'),
      phone: Yup.string().required('Campo obligatorio').matches(/^\d{10}$/, 'Teléfono debe tener 10 dígitos'),
    }),
    onSubmit: async (values: any) => {
      showAlert(
        'loading',
        'En un momento sera redirigido.',
        false
      );
      const selectedProduct = productData.filter(item => item.id === selectedRow)[0];
      const venta = [
        {
          title: selectedProduct.descripcion,
          quantity: 1,
          currency_id: "MXN",
          unit_price: selectedProduct.precio
        }
      ];
      await ClientServices.getInstance().getEmailInfo(values.email).then(async (data) => {
        if (data.respuesta.length !== 0) {
          if (data.respuesta[0].type === 'aprobado') {
            showAlert(
              'failed',
              'Ya existe un correo asociado a un producto nuestro, si esto es un error favor de comunicarse al correo kgerman@klausgermanph.com',
              true
            );
          } else {
            await createPayment(venta, values);
          }
        } else {
          await createPayment(venta, values);
        }
      });
    },
  });
  
  return (
    <div>
      <section id="planes" className={classes.section}>
        <div className={classes.imageContainer}>
          <img src={plan_logo_main} alt="Descripción de la imagen" className={classes.image} />
        </div>
        <div className={classes.listContainer}>
          <h1 className={classes.heading}>Nuestros planes incluyen</h1>
          {listData.map((item, index) => (
            <div key={index} className={classes.listItem}>
              <img src={item.imgSrc} alt={`Imagen ${index + 1}`} className={classes.listImage} />
              <p className={classes.listText}>{item.text}</p>
            </div>
          ))}
        </div>
      </section>

      <h1 className={classes.heading} style={{ textAlign: 'center' }}>Planes y precios</h1>
      <section className={classes.section}>
        <div className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Descripción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Radio
                        checked={selectedRow === row.id} // Marca el radio button si la fila es seleccionada
                        onChange={() => handleRowSelect(row.id)} // Maneja el cambio de selección
                      />
                      {row.descripcion} {/* Texto al lado del radio button */}
                    </TableCell>
                    <TableCell>{formatPrice(row.precio)}</TableCell> {/* Formateo del precio */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>

      <section className={classes.section}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          <span style={{textAlign: 'center'}}>Para continuar con tu compra, te pedimos llenes el siguiente formulario:</span>
          <TextField
            fullWidth
            label="Razón Social"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name ? String(formik.errors.name) : ''} // Solo muestra el mensaje si hay un error
            margin="normal"
          />
          <TextField
            fullWidth
            label="RFC"
            name="rfc"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.rfc && Boolean(formik.errors.rfc)}
            helperText={formik.touched.rfc && formik.errors.rfc ? String(formik.errors.rfc) : ''}
            margin="normal"
          />
          <TextField
            fullWidth
            label="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : ''}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Teléfono"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone ? String(formik.errors.phone) : ''}
            margin="normal"
          />
          <Button color="primary" variant="contained" type="submit" style={{ marginTop: '16px' }}>
            Enviar
          </Button>
        </form>
          <div className={classes.imageContainer}>
            <img src={plan_formulario} alt="Descripción de la imagen" className={classes.image} />
          </div>
      </section>
    </div>
  );
};

export default PlanesContent;
