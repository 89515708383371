import React, { useEffect } from 'react';
import './App.css'; // Importa tu archivo CSS global
import Header from './components/header/Header';
import PlataformaASGContent from './components/main/asgSection/PlataformaAsgContent';
import PlanesContent from './components/main/planSection/PlanesContent';
import ContactContent from './components/main/contactSection/ContactContent';
import CustomAlert from './components/main/shared/alert';
import useAlertStore from './state/alertState';
import Footer from './components/footer/Footer';
import { ClientServices } from './services/client.service.ts';

const App: React.FC = () => {
  // Llamamos al hook fuera del useEffect
  const { showAlert } = useAlertStore();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    const paymentId = queryParams.get('preference_id');

    const actualizarEstadoPago = async (type: string) => {
      await ClientServices.getInstance().getDataByIdMercado(paymentId).then(async (data) => {
        if (data.respuesta.length !== 0) {
          await ClientServices.getInstance().patchCambiarType(data.respuesta[0].id, type);
        }
      });
    };

    if (status === 'success') {
      showAlert(
        'success',
        'El pago ha sido un éxito. Pronto nos pondremos en contacto con usted.',
        true
      );
      actualizarEstadoPago('aprobado');
    } else if (status === 'failed') {
      showAlert(
        'failed',
        'El pago no se ha concretado. Inténtelo más tarde. Si el error persiste, comuníquese con su proveedor.',
        true
      );
      actualizarEstadoPago('rechazado');
    }
  }, [showAlert]);

  return (
    <div className='content'>
      <CustomAlert />
      <Header />
      <PlataformaASGContent />
      <PlanesContent />
      <ContactContent />      
      <Footer />
    </div>
  );
};

export default App;
