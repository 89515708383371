import { ClientServices } from "../../services/client.service.ts";

// mercadoPagoService.ts
export const createPayment = async (venta: any, body: any) => {
  try {
    const token = process.env.REACT_APP_MERCADO_PAGO_ACCESS_TOKEN;
    const KLAUS_EMAIL = process.env.KLAUS_EMAIL;
    const AX_EMAIL = process.env.AX_EMAIL;
    const response = await fetch("https://api.mercadopago.com/checkout/preferences", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        items: venta,
        back_urls: {
          success: `${window.location.origin}/?status=success`, // URL para pagos exitosos
          failure: `${window.location.origin}/?status=failed`, // URL para pagos fallidos
          pending: `${window.location.origin}/?status=pending` // URL para pagos pendientes (opcional)
        },
        auto_return: "approved"
        
      }),
    });

    const dataMercadoPago = await response.json();
    const createdDate = new Date().toISOString();
    const bodyAxios = {
      ...body, 
      idMercado: dataMercadoPago.id, 
      emailClient: `kgerman@klausgermanph.com`,  //correo de klaus
      emailAx: `servicios@axeleratum.com`, //correo de soporte de ax
      createdDate: createdDate, 
      lastModifiedDate: createdDate,
      type: 'pendiente'
    };
    await ClientServices.getInstance().putNuevaCompra(bodyAxios).then((data) => {
      if (dataMercadoPago.init_point) {
        window.location.href = dataMercadoPago.init_point; // Redirige al enlace de pago
        //window.open(dataMercadoPago.init_point, '_blank');
      } else {
        console.error("Error al crear el pago:", dataMercadoPago);
      }
    });
  } catch (error) {
    console.error("Error en la petición de pago:", error);
  }
};