// src/components/main/asgSection/PlataformaAsgContent.tsx
import React from 'react';
import useStyles from './styles'; // Importa los estilos
import asgLoop from '../../../assets/images/asgLoop.png';
import espacio_colaborativo from '../../../assets/images/espacio_colaborativo.png';
import reportes from '../../../assets/images/reportes.png';
import inteligencia_artificial from '../../../assets/images/inteligencia_artificial.png';
import monitoreo from '../../../assets/images/monitoreo.png';
import { Button } from '@mui/material';
import useAlertStore from '../../../state/alertState';

const PlataformaASGContent: React.FC = () => {
  const classes = useStyles();
  const { showAlert } = useAlertStore();


  const handleClickWithClose = () => {
    showAlert(
      '',
      `SAGA es un agente conversacional basado en Inteligencia Artificial que se utiliza para consultar 
      información de la documentación asociada a los procesos ASG. Las consultas de información que 
      realiza este agente,  NO salen a Internet, por lo que se asegura la confidencialidad y privacidad de
      la información. Saga es un agente conversacional privado que te permite interactuar de manera natural y 
      amigable con tu información`,
      true
    );
  };

  return (
    <div>
      <section id="plataforma-asg" className={classes.section}>
        <div className={classes.textContainer}>
          <h1 className={classes.heading}>Plataforma Integral de Cumplimiento ASG</h1>
          <p className={classes.paragraph}>
            <b>La Plataforma Integral de Cumplimiento ASG</b> permite la armonización de los procesos empresariales asociados al cumplimiento Ambiental, Social y de Gobierno.
          </p>
          <p className={classes.paragraph}>
            Ofrece un <b>espacio colaborativo accesible a múltiples usuarios</b>, optimizando el flujo documental entre los involucrados. <b>Incluye servicios de Inteligencia Artificial</b> para que los usuarios puedan “conversar” con la documentación respectiva.
          </p>
          <Button variant="contained" color="primary"  href='#planes' className={classes.button}>
            Cotiza tu plan
          </Button>
        </div>
        <div className={classes.imageContainer}>
          <img src={asgLoop} alt="Descripción de la imagen" className={classes.image} />
        </div>
      </section>

      <h1 className={classes.heading} style={{textAlign: 'center'}}>Conoce las características de nuestra plataforma</h1>
      
      <section id="plataforma-asg" className={classes.section}>
      <div className={classes.cardsContainer}>
        <div className={classes.card}>
          <img src={espacio_colaborativo} alt="Card 1" className={classes.cardImage} />
          <p className={classes.cardContent}><b>Espacio colaborativo:</b> creación, revisión, sistematización y análisis de documentos. </p>
        </div>
        <div className={classes.card}>
          <img src={reportes} alt="Card 1" className={classes.cardImage} />
          <p className={classes.cardContent}><b>Reportes automatizados</b> adaptables a diferentes regulaciones y estándares.</p>
        </div>
        <div className={classes.card}>
          <img src={inteligencia_artificial} alt="Card 1" className={classes.cardImage} />
          <p className={classes.cardContent}>
            <b>Inteligencia Artificial Privada SAGA</b> para análisis de datos y toma de decisiones. 
            <b onClick={handleClickWithClose} style={{ cursor: 'pointer'}}> Ver mas...</b>
          </p>
        </div>
        <div className={classes.card}>
          <img src={monitoreo} alt="Card 1" className={classes.cardImage} />
          <p className={classes.cardContent}><b>Monitoreo en tiempo real:</b> seguimiento continuo de métricas ASG.</p>
        </div>
      </div>
      </section>
    </div>
  );
};

export default PlataformaASGContent;
