import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => {
  const theme = useTheme();

  return {
    footer: {
      backgroundColor: '#f5f5f5',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '1200px',
      paddingBottom: '20px',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      },
    },
    buttons: {
      outline: 'transparent',
      backgroundColor: 'transparent', // Fondo transparente
      boxShadow: 'none', // Sin sombras
      '&:hover': {
        color: '#C4E9F1', // Cambia el color del texto al pasar el mouse
        backgroundColor: 'transparent', // Mantener el fondo transparente en hover
      },
    },
    buttonsDiv: {
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    logoDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      maxWidth: '100px',
      marginBottom: '10px',
    },
    socialIcons: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    divider: {
      width: '100%',
      border: '0.5px solid #243C53',
      margin: '20px 0',
    },
    bottomText: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '1200px',
      fontSize: '14px',
      color: '#243C53',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
      },
    },
    leftText: {
      textAlign: 'left',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
      },
    },
    rightText: {
      textAlign: 'right',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
      },
    },
  };
});

export default useStyles;
