// Importa los estilos
import useStyles from './styles';
import { Button, IconButton } from '@mui/material';
import klausLogo from '../../assets/images/klausLogo.png';
import { Facebook, LinkedIn, Instagram, X } from '@mui/icons-material';

const Footer = () => {
  const classes = useStyles();

  // Función para manejar la redirección a redes sociales
  const handleSocialClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.content}>
        {/* Sección de botones */}
        <div className={classes.buttonsDiv}>
          <Button sx={{ color: '#243C53' }} className={classes.buttons} variant="text" href="#plataforma-asg">Plataforma ASG</Button>
          <Button sx={{ color: '#243C53' }} className={classes.buttons} variant="text" href="#planes">Planes</Button>
          <Button sx={{ color: '#243C53' }} className={classes.buttons} variant="text" href="#contacto">Contacto</Button>
        </div>

        {/* Sección de logotipos e iconos */}
        <div className={classes.logoDiv}>
          <img src={klausLogo} alt="Klaus Logo" className={classes.logo} />
          <div className={classes.socialIcons}>
            <IconButton onClick={() => handleSocialClick('https://www.facebook.com/KlausGermanPh')} aria-label="Facebook">
              <Facebook sx={{ color: '#243C53' }} />
            </IconButton>
            <IconButton onClick={() => handleSocialClick('https://x.com/KLAUSGERMANPH')} aria-label="Twitter">
              <X sx={{ color: '#243C53' }} />
            </IconButton>
            <IconButton onClick={() => handleSocialClick('https://www.linkedin.com/in/klaus-german-phinder-ganarganar/')} aria-label="LinkedIn">
              <LinkedIn sx={{ color: '#243C53' }} />
            </IconButton>
            <IconButton onClick={() => handleSocialClick('https://www.instagram.com/klausgermanph')} aria-label="Instagram">
              <Instagram sx={{ color: '#243C53' }} />
            </IconButton>
          </div>
        </div>
      </div>

      {/* Línea divisoria */}
      <hr className={classes.divider} />

      {/* Sección de texto inferior */}
      <div className={classes.bottomText}>
        <span className={classes.leftText}>©Axeleratum 2024. Todos los derechos reservados.</span>
        <span className={classes.rightText}>Términos y condiciones</span>
      </div>
    </footer>
  );
};

export default Footer;
