// src/components/styles.js

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px 20px',
    zIndex: 1000,
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '10px',
    },
  },
  images: {
    marginLeft: '5%',
    display: 'flex',
    alignItems: 'center',
    gap: '50%',
    '@media (max-width: 768px)': {
      marginLeft: '0',
      gap: '10px',
    },
  },
  buttonsDiv: {
    display: 'flex',
    gap: '20px',
    marginRight: '15%',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      marginRight: '0',
      gap: '10px',
    },
  },
  buttons: {
    outline: 'transparent',
    backgroundColor: 'transparent', // Fondo transparente
    boxShadow: 'none', // Sin sombras
    '&:hover': {
      color: '#C4E9F1', // Cambia el color del texto al pasar el mouse
      backgroundColor: 'transparent', // Mantener el fondo transparente en hover
    },
  },
  content: {
    paddingTop: '70px',
  },
});

export default useStyles;
