import React from 'react';
import { Backdrop, Button, Typography, Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAlertStore from '../../../state/alertState';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1300,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px',
    background: 'linear-gradient(142deg, rgba(196,233,241,1) 1%, rgba(250,250,250,1) 25%, rgba(250,250,250,1) 75%, rgba(196,233,241,1) 100%)',
    borderRadius: '8px',
    maxWidth: '90%',
    width: 400,
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
  },
  image: {
    width: '100px',
    height: '100px',
    marginBottom: '16px',
  },
  text: {
    marginBottom: '16px',
    color: 'black',
  },
  closeButton: {
    marginTop: '16px',
  },
});

const CustomAlert: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isVisible, imageSrc, text, onClose, hideAlert } = useAlertStore();

  if (!isVisible) return null;

  return (
    <Backdrop className={classes.backdrop} open>
      <Box className={classes.alertContainer} style={{ borderRadius: theme.shape.borderRadius }}>
        {imageSrc === 'loading' ? (<CircularProgress />) : 
          imageSrc === 'success' ? (<CheckCircleIcon color='success' />) : 
            imageSrc === 'failed' ? (<CancelIcon color='secondary' />) : null}
        <Typography variant="h6" className={classes.text}>
          {text}
        </Typography>
        {onClose && (
          <Button
            variant="contained"
            color="primary"
            onClick={hideAlert}
            className={classes.closeButton}
          >
            Cerrar
          </Button>
        )}
      </Box>
    </Backdrop>
  );
};

export default CustomAlert;
