import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  section: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px',
    margin: '0 auto',
    textAlign: 'justify',
  },
  textContainer: {
    flex: 1,
    marginRight: '20px',
  },
  heading: {
    marginBottom: '20px',
    color: '#243C53',
  },
  paragraph: {
    color: '#243C53',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  button: {
    marginTop: '20px',
    textTransform: 'none',
  },
  cardsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '30px',
    gap: '20px',
  },
  card: {
    width: '22%',
    background: 'linear-gradient(142deg, rgba(196,233,241,1) 1%, rgba(250,250,250,1) 25%, rgba(250,250,250,1) 75%, rgba(196,233,241,1) 100%)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    textAlign: 'center',
    borderBottom: '10px solid #1356DA', // Borde solo en la parte inferior
    borderRadius: '8px', // Bordes redondeados en todo el card
  },
  cardImage: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '10px', // Espacio debajo de la imagen
    borderRadius: '8px',
  },
  cardContent: {
    color: '#243C53',
    textAlign: 'start',
  },
  // Consulta de medios para pantallas de 1200px o menos
  '@media (max-width: 1200px)': {
    cardsContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      width: '80%',
      marginBottom: '20px',
    },
  },
});

export default useStyles;
